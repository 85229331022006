<!-- eslint-disable no-console -->
<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import api from "../../../../common/axios";

/**
 * Advanced table component
 */
export default {
  page: {
    title: "Pusat Notifikasi",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      config: {
        api: "notification",
        showNotification: "notification/",
      },
      title: "Notifikasi",
      items: [
        {
          text: "",
        },
      ],
      notifiCationList: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
    };
  },
  watch: {
    currentPage() {
      this.getData();
    },
  },
  methods: {
    redirectLink(i) {
      let type = i.data.type;
      let id_data = i.data.id;
      let _ = this;
      switch (type) {
        case "job":
          _.getNotifDetail(i.id);
          _.$router.push({ path: `/job/${id_data}/show` });
          break;
        case "registration_lc":
          _.getNotifDetail(i.id);
          _.$router.push({ path: `/logistic/${id_data}/show` });
          break;
        case "chat_lc":
          _.getNotifDetail(i.id);
          _.$router.push({ path: `/chat?id=${3}` });
          break;
        case "chat_truck_owner":
          _.getNotifDetail(i.id);
          _.$router.push({ path: `/chat?id=${1}` });
          break;
        case "chat_driver":
          _.getNotifDetail(i.id);
          _.$router.push({ path: `/chat?id=${2}` });
          break;
        case "registration_truck_owner":
        case "suspended_truck_owner": {
          _.getNotifDetail(i.id);
          _.$router.push({ path: `/truck-owner/${id_data}/show` });
          break;
        }
        case "registration_driver":
        case "suspended_driver": {
          _.getNotifDetail(i.id);
          _.$router.push({ path: `/truck-driver/${id_data}/show` });
          break;
        }
      }
    },
    getData() {
      let _ = this;
      api
        .get(_.config.api, {
          params: {
            page: this.currentPage,
            per_page: this.perPage,
            orderby: "created_at",
            sort: "desc",
          },
        })
        .then((response) => {
          this.notifiCationList = response.data.data.rows;
          this.totalRows = response.data.data.total_data;
        });
    },

    getNotifDetail(id) {
      let _ = this;
      api.get(_.config.showNotification + id).then(() => {
        _.getData();
      });
    },
  },

  mounted() {
    let _ = this;
    _.getData();
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- /.modal -->
            <div class="list-job mx-2 my-3">
              <div
                class="row border border-primary rounded py-1 mb-2"
                v-for="(i, index) in notifiCationList"
                :key="index"
                @click="redirectLink(i)"
                style="cursor: pointer"
              >
                <div class="col-sm-12 py-2">
                  <div class="row">
                    <div class="col-md-10">
                      <h5>{{ i.subject }}</h5>
                    </div>
                    <div class="col-md-2">
                      <p class="font-size-11 font-weight-bolder float-right">
                        {{ i.created_at | moment("D MMMM YYYY hh:mm") }}
                      </p>
                    </div>
                  </div>
                  <h6>{{ i.body }}</h6>
                </div>
              </div>
            </div>
          </div>

          <div class="row mb-4" v-if="notifiCationList.length > 0">
            <div class="col">
              <div
                class="dataTables_paginate paging_simple_numbers float-right"
              >
                <ul class="pagination pagination-rounded mb-0">
                  <!-- pagination -->
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                  ></b-pagination>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
